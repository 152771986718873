import Footer from "./components/Footer";
import Header from "./components/Header";
import Product from "./components/Product";
import { products } from "./products";

function App() {
  return (
    <div className="bg-white">
      <Header />

      <div className="py-8 max-w-[1366px] mx-auto">
        <h2 className="text-3xl font-bold tracking-tight text-gray-700 p-3">
          Нова колекция
        </h2>

        <div className="mt-6 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-6 gap-y-10 p-3">
          {products.map((product) => (
            <Product key={product.id} product={product} />
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default App;
