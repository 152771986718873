export const products = [
  {
    id: 1,
    name: "Дамски кецове Lena Black",
    href: "https://tintishoes.com/damski-ketsove-lena-black-lak",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-kecove-lena-black-lak/tinti-shoes-damski-kecove-lena-black-lak1-860x860.webp",
    price: 149,
  },
  {
    id: 2,
    name: "Дамски кецове Kami White",
    href: "https://tintishoes.com/damski-ketsove-kami-white",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-kecove-kami-white/tinti-shoes-damski-kecove-kami-white-860x860.webp",
    price: 142,
  },
  {
    id: 3,
    name: "Дамски кецове Geri Black",
    href: "https://tintishoes.com/damski-ketsove-geri-black",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-kecove-geri-black/tinti-shoes-damski-kecove-geri-black-860x860.webp",
    price: 149,
  },
  {
    id: 4,
    name: "Дамски кецове Adel Gold",
    href: "https://tintishoes.com/damski-ketsove-adel-gold",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-kecove-adel-gold/tinti-shoes-damski-kecove-adel-gold-860x860.webp",
    price: 149,
  },
  {
    id: 5,
    name: "Дамски кецове Adel Silver",
    href: "https://tintishoes.com/damski-ketsove-adel-silver",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-kecove-adel-silver/tinti-shoes-damski-kecove-adel-silver2-860x860.webp",
    price: 149,
  },
  {
    id: 6,
    name: "Дамски кецове Tinti brand white",
    href: "https://tintishoes.com/damski/damski-sportni-obuvki/damski-ketsove-tinti-brand-white?limit=50",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-kecove-tinti-brand-white/tinti-shoes-damski-kecove-tinti-brand-white4-680x680.webp",
    price: 159,
    noDiscount: true
  },
  {
    id: 7,
    name: "Дамски кецове Beti white",
    href: "https://tintishoes.com/damski/damski-sportni-obuvki/damski-ketsove-beti-white?limit=50",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-kecove-beti-white-new/tinti-shoes-damski-kecove-beti-white-new5-680x680.webp",
    price: 149,
    noDiscount: true
  },
  {
    id: 8,
    name: "Дамски кецове с брокат Yana brokat",
    href: "https://tintishoes.com/damski/damski-sportni-obuvki/damski-ketsove-s-brokat-yana-brokat?limit=50",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-kecove-yana-brokat/tinti-shoes-damski-kecove-yana-brokat4-680x680.webp",
    price: 159,
    noDiscount: true
  },

  {
    id: 9,
    name: "Дамски кецове Lora white",
    href: "https://tintishoes.com/damski/damski-sportni-obuvki/damski-ketsove-lora-white?limit=50",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-kecove-lora-white/tinti-shoes-damski-kecove-lora-white5-680x680.webp",
    price: 159,
    noDiscount: true
  },

  {
    id: 10,
    name: "Дамски кецове Beti beige",
    href: "https://tintishoes.com/damski/damski-sportni-obuvki/damski-ketsove-beti-beige?limit=50",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-kecove-beti-beige/tinti-shoes-damski-kecove-beti-beige3-680x680.webp",
    price: 149,
    noDiscount: true
  },

  {
    id: 11,
    name: "Дамски кецове Krasi White Silver",
    href: "https://tintishoes.com/damski-ketsove-krasi-white-silver",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-kecove-krasi-white-silver/tinti-shoes-damski-kecove-krasi-white-silver6-860x860.webp",
    price: 149,
    noDiscount: true
  },

  {
    id: 12,
    name: "Дамски сникърси Tereza Black",
    href: "https://tintishoes.com/damski-snikarsi-tereza-black",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-snikarsi-tereza-black/tinti-shoes-damski-snikarsi-tereza-black4-860x860.webp",
    price: 159,
  },

  {
    id: 13,
    name: "Дамски кецове Beti Pink",
    href: "https://tintishoes.com/damski-ketsove-beti-pink",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-kecove-beti-pink/tinti-shoes-damski-kecove-beti-pink2-860x860.webp",
    price: 149,
  },

  {
    id: 14,
    name: "Дамски кецове Tinti brand black",
    href: "https://tintishoes.com/damski/damski-sportni-obuvki/damski-ketsove-tinti-brand-black?limit=50",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-kecove-tinti-brand-black/tinti-shoes-damski-kecove-tinti-brand-black6-680x680.webp",
    price: 159,
  },

  {
    id: 15,
    name: "Дамски сникърси Tereza White",
    href: "https://tintishoes.com/damski-snikarsi-tereza-white",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-snikarsi-tereza-white/tinti-shoes-damski-snikarsi-tereza-white4-860x860.webp",
    price: 159,
  },
  

  {
    id: 16,
    name: "Дамски кецове Geri Silver",
    href: "https://tintishoes.com/damski-ketsove-geri-silver",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-kecove-geri-silver/tinti-shoes-damski-kecove-geri-silver4-860x860.webp",
    price: 149,
  },

  {
    id: 17,
    name: "Дамски кецове Geri Beige",
    href: "https://tintishoes.com/damski-ketsove-geri-beige",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-kecove-geri-beige/tinti-shoes-damski-kecove-geri-beige-860x860.webp",
    price: 159,
  },

  {
    id: 18,
    name: "Дамски сникърси Lia Black",
    href: "https://tintishoes.com/damski-snikarsi-lia-black",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-kecove-lia-black/tinti-shoes-damski-kecove-lia-black5-860x860.webp",
    price: 159,
  },
  {
    id: 19,
    name: "Дамски сникърси Marina Black",
    href: "https://tintishoes.com/damski-snikarsi-marina-black",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-snikarsi-marina-black/tinti-shoes-damski-snikarsi-marina-black10-860x860.webp",
    price: 149,
  },
  {
    id: 20,
    name: "Черни дамски кецове Adel Black",
    href: "https://tintishoes.com/cherni-damski-ketsove-adel-black",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-kecove-adel-black/tinti-shoes-damski-kecove-adel-black9-860x860.webp",
    price: 149,
  },
  {
    id: 21,
    name: "Дамски сникърси Loli White Black",
    href: "https://tintishoes.com/damski-snikarsi-loli-white-black",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-snikarsi-loli-white-black/tinti-shoes-damski-snikarsi-loli-white-black-860x860.webp",
    price: 159,
  },

  {
    id: 22,
    name: "Дамски кецове Adel White",
    href: "https://tintishoes.com/damski-ketsove-adel-white",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-kecove-adel-white/tinti-shoes-damski-kecove-adel-white13-860x860.webp",
    price: 149,
  },
  {
    id: 23,
    name: "Дамски кецове Adel Beige",
    href: "https://tintishoes.com/damski-ketsove-adel-beige",
    imageSrc:
      "https://tintishoes.com/image/cachewebp/catalog/products/tinti-shoes-damski-kecove-adel-beige/tinti-shoes-damski-kecove-adel-beige5-860x860.webp",
    price: 149,
  },
];
