function Footer() {
  return (
    <footer>
      <div className="flex justify-center items-center mx-auto max-w-[1366px] p-3 text-gray-600 text-sm">
        <span className="pr-1">
          Copyright &copy; {new Date().getFullYear()} by
        </span>
        <a
          className="underline font-semibold"
          rel="noreferrer"
          target="_blank"
          href="https://amexsoft.net/bg"
        >
          AmexSoft
        </a>
      </div>
    </footer>
  );
}

export default Footer;
