function Product(props) {
  const { href, imageSrc, name, price, noDiscount } = props.product;

  const discount = (price) => {
    const oldPrice = Math.round(price * 1.3);
    return oldPrice.toFixed(2);
  };

  return (
    <div className="group relative">
      <img
        className="absolute top-2 right-2 w-14 sm:w-20"
        src="/100-leather.png"
        alt="100%-обувки-от-естествена-кожа"
      />
      <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
        <a rel="noreferrer" target="_blank" href={href} className="block">
          <img
            src={imageSrc}
            alt={name}
            className="h-full w-full object-cover object-center group-hover:opacity-75"
          />
        </a>
      </div>
      <h3 className="mt-4 text-sm text-gray-900 whitespace-nowrap text-ellipsis overflow-hidden">
        {name}
      </h3>
      <div className="flex items-center gap-1 mt-1">
        {noDiscount && (
          <p className="text-sm font-medium text-gray-400 line-through decoration-black">
            {discount(price)} лв.
          </p>
        )}
        <p className="text-lg font-medium text-gray-700">
          {price.toFixed(2)} лв.
        </p>
      </div>

      <a
        class="inline-flex py-1 px-3 font-semibold items-center gap-1.5 bg-white border text-gray-700 border-gray-700 rounded-full text-sm mt-4 hover:shadow hover:bg-gray-50 transition-all"
        rel="noreferrer"
        target="_blank"
        href={href}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.7}
          stroke="currentColor"
          class="size-[18px]"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
          ></path>
        </svg>
        <span>Разгледайте още</span>
      </a>
    </div>
  );
}

export default Product;
