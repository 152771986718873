function Header() {
  return (
    <header className="sticky top-0 left-0 z-50 shadow-lg py-3 bg-white">
      <div className="flex items-center justify-between max-w-[1366px] mx-auto p-3">
        <img
          className="w-[100px] sm:w-[120px]"
          src="https://tintishoes.com/image/catalog/tinti-shoes-logo.png"
          alt="tinty-shoes-лого"
        />
        <a
          className="px-4 sm:px-3 text-sm py-2 border-2 border-gray-600 bg-white shadow-md hover:shadow-none hover:bg-gray-50 rounded-full text-ellipsis transition-all whitespace-nowrap"
          href="https://tintishoes.com/"
        >
          Към магазина
        </a>
      </div>
    </header>
  );
}

export default Header;
